import React from "react";
import { InfraScanSchema, ReportItem, Scan } from "common";
import ResultTable from "./results/ResultTable";

interface Props {
  scan: Scan;
  reportItems: ReportItem[];
  selectedSeverity?: string;
  onNextClick: (last: ReportItem) => void;
  onPreviousClick: (previousReportItem: ReportItem) => void;
  onFocusReportItem: (reportItem: ReportItem) => void;
  onSeverityClick: (severity: any) => void;
  previousButtonDisabled: boolean;
  onTargetChange: (targetId: number) => void;
  nextButtonDisabled: boolean;
}

export default function InfrastructureTab({
  scan,
  onTargetChange,
  reportItems,
  selectedSeverity,
  onSeverityClick,
  onNextClick,
  onPreviousClick,
  previousButtonDisabled,
  nextButtonDisabled,
  onFocusReportItem
}: Props) {
  return (
    <>
      <ResultTable
        onTargetChange={onTargetChange}
        type={InfraScanSchema.properties.Type.const}
        scan={scan}
        reportItems={reportItems}
        selectedSeverity={selectedSeverity}
        onSeverityClick={onSeverityClick}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        previousButtonDisabled={previousButtonDisabled}
        nextButtonDisabled={nextButtonDisabled}
        onFocusReportItem={onFocusReportItem}
      />
    </>
  );
}
