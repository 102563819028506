import React from "react";
import { Scan, ReportItem, InfraScanSchema, CodeScanSchema } from "common";
import ResultTable from "./results/ResultTable";

interface Props {
  scan: Scan;
  reportItems: ReportItem[];
  selectedSeverity?: string;
  onNextClick: (last: ReportItem) => void;
  onPreviousClick: (previousReportItem: ReportItem) => void;
  onFocusReportItem: (reportItem: ReportItem) => void;
  onSeverityClick: (severity: any) => void;
  previousButtonDisabled: boolean;
  nextButtonDisabled: boolean;
}

export default function CodeScanTab({
  scan,
  reportItems,
  selectedSeverity,
  onSeverityClick,
  onNextClick,
  onPreviousClick,
  previousButtonDisabled,
  nextButtonDisabled,
  onFocusReportItem,
}: Props) {
  return (
    <>
      <div className="bg-slate-950 p-10 rounded-md">
        <h4 className="text-violet-300 text-2xl">Code Scan Results</h4>
      </div>
      <ResultTable
        type={CodeScanSchema.properties.Type.const}
        scan={scan}
        reportItems={reportItems}
        selectedSeverity={selectedSeverity}
        onSeverityClick={onSeverityClick}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        previousButtonDisabled={previousButtonDisabled}
        nextButtonDisabled={nextButtonDisabled}
        onFocusReportItem={onFocusReportItem}
      />
    </>
  );
}
