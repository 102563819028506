import React, { useState, useEffect } from "react";
import {
  AWS_COMPLIANCES,
  ComplianceResult,
  InfraScanPayload,
  Scan,
} from "common";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

interface Props {
  scan: Scan;
  maxNumber?: number;
  sort?: boolean;
  title: string;
  onFrameworkClick: (id: number) => void;
}

interface Progress {
  id: number;
  name: string;
  percentage: number;
}

export default function ComplianceTab({
  scan,
  title,
  onFrameworkClick,
  sort,
  maxNumber,
}: Props) {
  const [progressBarsPerTarget, setProgressBarsPerTarget] = useState(
    undefined as unknown as { id: number; compliances: any[] }
  );

  const compute = (target: InfraScanPayload): (Progress | undefined)[] => {
    // Assume AWS for MVP
    const results =  target.ComplianceResults?.map((complianceResult) => {
      const complianceFramework = AWS_COMPLIANCES.find(
        (c) => c.name === complianceResult.ComplianceFramework
      );

      if (!complianceFramework) {
        return undefined;
      }

      return {
        id: complianceFramework.id,
        name: complianceFramework.name,
        percentage:
          (complianceResult.Passes /
            (complianceResult.Failures +
              complianceResult.Passes +
              complianceResult.Other)) *
          100,
      };
    });

    if (sort && results) {
      return results.sort((c1, c2) => {
        if (sort) {
          return 1;
        }
  
        if (!c1 || !c2) {
          return -1;
        }
  
        return c1?.percentage - c2?.percentage;
      });
    }
    else {
      return results as (Progress | undefined)[]
    }
  };

  return (
    <>
      {scan.Targets &&
        scan.Targets.filter((t) => t.Type === "InfrastructureScan").map((scan) => {
          const infraScan = scan as InfraScanPayload;

          const progressBars = compute(infraScan);

          return (
            <div className="p-6 rounded-lg shadow">
              <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                {title}
              </h5>
              <p className="mb-2 text-lg font-bold tracking-tight text-gray-900 dark:text-white">
                See Findings for {infraScan.CloudProvider.toUpperCase()} below:
              </p>

              {progressBars &&
                progressBars
                  .slice(0, maxNumber ? maxNumber : progressBars.length)
                  .map((element) => {
                    if (!element) {
                      return "";
                    }
                    return (
                      <div key={element.id} className="relative w-full mb-4">
                        <div className="flex justify-between items-center bg-gray-200 rounded-full dark:bg-gray-700 h-6">
                          {/* Red progress bar */}
                          <div
                            className="bg-gradient-to-l from-red-600 to-orange-600 rounded-full h-full"
                            style={{ width: `${element.percentage}%` }}
                          ></div>

                          {/* Text overlay */}
                          <div className="absolute inset-0 flex justify-center items-center">
                            <span className="text-white text-xs font-medium">
                              {`${element.name} - ${element.percentage}%`}
                            </span>
                          </div>

                          {/* Chevron button */}
                          <button
                            className="absolute right-2"
                            onClick={() => onFrameworkClick(element.id)}
                          >
                            <Icon
                              size={0.75}
                              path={mdiChevronRight}
                              className="text-white"
                            ></Icon>
                          </button>
                        </div>
                      </div>
                    );
                  })}

              <a
                href="https://aws.amazon.com/compliance/"
          className="inline-flex items-center ml-4 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
              >
                Read more
                <svg
                  className="rtl:rotate-180 w-3.5 h-3.5 ms-2"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 14 10"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke-width="2"
                    d="M1 5h12m0 0L9 1m4 4L9 9"
                  />
                </svg>
              </a>
            </div>
          );
        })}
    </>
  );
}
