import { useEffect, useState } from "react";
import { CodeScanPayload, InfraScanPayload, Scan } from "common/src/types/Scan";
import ScanService from "../../../service/ScanService";
import Icon from "@mdi/react";
import { mdiCheckCircle, mdiClock, mdiCubeScan, mdiHome } from "@mdi/js";
import React from "react";
import BreadCrumbs from "../../Breadcrumbs";
import Badge from "../../Badge";
import Loading from "../../Loading";
import { useNavigate } from "react-router-dom";

interface Props {
  scanService: ScanService;
}

export default function ListScan({ scanService }: Props) {
  const navigate = useNavigate();
  const [reports, setReports] = useState([] as Scan[]);
  const [loaded, setLoaded] = useState(false);

  const loadReports = async () => {
    const reports = await scanService.list();
    setReports(reports.reverse());
    setLoaded(true);
  };

  useEffect(() => {
    if (!loaded) loadReports();
  });

  const createBadge = (target: InfraScanPayload | CodeScanPayload) => {
    if (target.Type === "InfrastructureScan") {
      return (
        <Badge
          backgroundColor="gray-700"
          textColor="white-400"
          iconPath={target.FinishedAt ? mdiCheckCircle : mdiClock}
          text={target.Type}
          borderColor={""}
          className="rounded-md"
        />
      );
    }
    if (target.Type === "CodeScan") {
      return (
        <Badge
          backgroundColor="gray-700"
          textColor="white-400"
          iconPath={target.FinishedAt ? mdiCheckCircle : mdiClock}
          text={target.Type}
          borderColor={""}
        />
      );
    }

    return <></>;
  };

  if (!loaded || !reports) {
    return <Loading />;
  }
  return (
    <>
      <div className="pt-10">
        <BreadCrumbs
          items={[
            { iconPath: mdiHome, text: "home", link: "/home" },

            { text: "Scan", link: "/scan" },
          ]}
        />
      </div>

      <div className="mb-4 mt-8">
        <a
          href="/scan/new"
          type="button"
          className="ml-4 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center m-8"
        >
          New Scan
        </a>
      </div>
      <div className="grid grid-cols-3 gap-4 flex-wrap items-center justify-between mx-auto p-4">
        <div className="max-w-sm p-6  bg-white border border-gray-200 dark:border-gray-900 rounded-lg shadow bg-gradient-to-bl from-green-900 from-1% via-gray-900 via-30% to-gray-900 to-90%">
          <p className="flex space-x-4 text-green-900 dark:text-green-400">
            <Icon path={mdiCubeScan} size={1.5} />
            <h5 className="mb-2 text-2xl font-bold tracking-tight ">
              {reports.length} Requested Scan{reports.length === 1 ? "" : "s"}
            </h5>
          </p>
        </div>

        <div className="max-w-sm p-6  bg-white border border-gray-200 dark:border-gray-900 rounded-lg shadow bg-gradient-to-bl from-yellow-900 from-1% via-gray-900 via-30% to-gray-900 to-90%">
          <a
            href="#"
            className="flex space-x-4  text-yellow-900 dark:text-yellow-400"
          >
            <Icon path={mdiClock} size={1.5} />
            <h5 className="mb-2 text-2xl font-bold tracking-tight">
              {reports.filter((s) => !s.FinishedAt).length} In Progress
            </h5>
          </a>
        </div>

        <div className="max-w-sm p-6  bg-white border border-gray-200 dark:border-gray-900 rounded-lg shadow bg-gradient-to-bl from-indigo-900 from-1% via-gray-900 via-30% to-gray-900 to-90%">
          <a
            href="#"
            className="flex space-x-4 text-indigo-900 dark:text-indigo-400"
          >
            <Icon path={mdiCheckCircle} size={1.5} />
            <h5 className="mb-2 text-2xl font-bold tracking-tight">
              {reports.filter((s) => s.FinishedAt).length} Finished
            </h5>
          </a>
        </div>
      </div>

      <table className="p-36 w-full text-sm text-left rtl:text-right">
        <thead className=" text-xs dark:text-gray-300 uppercase  dark:bg-gray-950 shadow-sm">
          <tr className="">
            <th scope="col" className="px-6 py-3">
              Report Id
            </th>
            <th scope="col" className="px-6 py-3">
              Scan Start Time
            </th>
            <th scope="col" className="px-6 py-3">
              View
            </th>
            <th scope="col" className="px-6 py-3">
              Scan Type
            </th>
          </tr>
        </thead>
        <tbody>
          {reports &&
            reports.map((r, index) => {
              const report = r as Scan;
              return (
                <tr
                  className={
                    index % 2
                      ? "text-gray-900 dark:text-gray-300"
                      : "dark:bg-slate-900 dark:text-gray-300"
                  }
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-gray-400"
                  >
                    {report.ReportId}
                  </th>
                  <td className="px-6 py-4">
                    {new Date(report.CreatedAt).toTimeString()}
                  </td>
                  <td className="px-6 py-4">
                    {report.FinishedAt && (
                      <button
                        onClick={() => navigate(`/scan/${report.ReportId}`)}
                        type="button"
                        className="ml-4 text-white bg-gradient-to-br bg-indigo-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                      >
                        See Details
                      </button>
                    )}
                  </td>
                  <td className="px-6 py-4 space-x-2">
                    {report.Targets &&
                      report.Targets.map((t) => createBadge(t))}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </>
  );
}
