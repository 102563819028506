import { mdiRobotOutline, mdiShieldAlert } from "@mdi/js";
import Icon from "@mdi/react";
import { AWS_COMPLIANCES, ReportItem } from "common";
import React from "react";
import "@mdi/font/css/materialdesignicons.min.css"; // Allows us to use icon with Gradient
import Badge, { fromReportItem } from "../../../Badge";
import { colorCodes } from "../../../../ColorCodes";
import ReactMarkdown from "react-markdown";

interface Props {
  reportItem: ReportItem;
  onClick: (e: any) => void;
  onEnrichRemediationWithAi: (r: ReportItem) => void;
  buttonText: string;
}

export default function ReportItemDetailModal({
  reportItem,
  onClick,
  onEnrichRemediationWithAi,
  buttonText,
}: Props) {
  return (
    <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="mt-20 relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all w-1/2">
            <div className="bg-gray-700 px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div
                  className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full ${
                    colorCodes[reportItem?.Finding?.severity?.toString() || 'UNKNOWN']?.backgroundColor || "bg-gray-500"
                  } sm:mx-0 sm:h-10 sm:w-10`}
                >
                  <Icon path={mdiShieldAlert} size={1.5} color={""} />
                </div>
                <div className="absolute right-6 text-gray-400 pt-2">
                  Threat Detected at:{" "}
                  {reportItem?.Finding?.time ? new Date(reportItem.Finding.time).toUTCString() : "N/A"}
                </div>
                <div className="mt-5 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <div className="mt-2">{reportItem ? fromReportItem(reportItem) : "No data available"}</div>
                  <div className="mt-2">
                    <br />
                    <p className="text-sm text-gray-100">
                      {reportItem?.Finding?.vulnerabilities?.[0]?.title || "No vulnerabilities found"}
                    </p>
                    <span className="inline-flex mt-5">
                      <Badge
                        text={reportItem?.Finding?.vulnerabilities?.[0]?.cve || "N/A"}
                        backgroundColor={"bg-indigo-400"}
                        textColor={"text-white"}
                        borderColor={"border-indigo-400"}
                        className="rounded-l-md px-3 py-2"
                      />
                      <Badge
                        text={reportItem?.Finding?.resources?.[0]?.name || "N/A"}
                        backgroundColor={"bg-gray-500"}
                        textColor={"text-white"}
                        borderColor={"border-transparent"}
                        className="rounded-r-md px-3 py-2"
                      />
                    </span>
                    <div className="my-8 text-white">
                      <div className="flex items-center pb-4">
                        <h4 className="text-xl pb-4 mt-2">Recommended Remediation</h4>
                      </div>
                      <div className="markdown-content">
                        <ReactMarkdown
                          components={{
                            p: ({ node, ...props }) => <p className={"py-4"} {...props} />,
                            a: ({ node, ...props }) => <a className={"text-blue-600"} {...props} />,
                          }}
                        >
                          {reportItem?.Finding?.vulnerabilities?.[0]?.remediation?.desc || "No remediation available"}
                        </ReactMarkdown>
                      </div>
                    </div>

                    <h5 className="text-gray-200 py-4">Additional Context</h5>
                    {JSON.stringify(reportItem?.Finding?.finding_info ?? {}, null, 2)}
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-900 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button
                type="button"
                className="inline-flex w-full justify-center rounded-md bg-gradient-to-br from-purple-600 to-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
                onClick={onClick}
              >
                {buttonText || "Close"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
