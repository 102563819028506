import React, { useState } from "react";
import { ReactComponent as Logo } from "../../logo.svg";
import AuthService from "../../service/AuthService";
import { useNavigate } from "react-router-dom";

interface Props {
  authService: AuthService;
  onCollapseButtonClick: () => void;
  collapsed?: boolean;
}

export default function Sidebar({ authService, collapsed, onCollapseButtonClick }: Props) {
  const [selected, setSelected] = useState("My Scans");
  const navigate = useNavigate();

  // Define the sidebar items as an array of objects
  const sidebarItems = [
    {
      icon: "mdi-view-list",
      text: "My Scans",
      path: "/scan",
      category: "Scan",
    },
    { icon: "mdi-cube-scan", text: "New", path: "/scan/new", category: "Scan" },
    {
      icon: "mdi-account-cog",
      text: "Settings",
      path: "/account",
      category: "Account",
    },
  ];

  return (
    <>
      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-40 ${collapsed ? "w-20" : "w-64"} h-screen -translate-x-full sm:translate-x-0 transition-all duration-150`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 overflow-y-auto bg-slate-900  shadow-sm">
          <span className="flex items-center text-3xl py-4 pl-2 text-white">
            <div className="w-10 h-10 p-1 rounded-full">
              {/* Gradient applied directly to the SVG */}
              <Logo className="w-full h-full bg-clip-text bg-gradient-to-r text-indigo-300" />
            </div>
            {!collapsed && <h1 className="text-xl py-8 pl-2"> ThreatScope</h1>}
          </span>
          {/* Render categories and items */}
          {["Overview", "Scan", "Account"].map((category) => (
            <div key={category} className="pt-4 pl-2">
              <ul className="space-y-4 font-medium w-full pr-8">
                {sidebarItems
                  .filter((item) => item.category === category)
                  .map((item) => (
                    <SidebarItem
                      key={item.text}
                      icon={item.icon}
                      text={item.text}
                      path={item.path}
                      selected={selected === item.text}
                      collapsed={collapsed}
                      onClick={(path) => {
                        setSelected(item.text);
                        navigate(path);
                      }}
                    />
                  ))}
              </ul>
            </div>
          ))}
          <div className="pt-4 pl-2 pr-8">
            <SidebarItem
              icon={"mdi-logout"}
              text="Logout"
              onClick={async () => {
                await authService.signOut();
                navigate("/");
              }}
              path={""}
              collapsed={collapsed}
              selected={false}
            />
          </div>

          <div className="mt-auto flex justify-en absolute bottom-10 w-full pl-2 transition-all duration-150">
            <button
              onClick={onCollapseButtonClick}
              className="flex items-center space-x-2 text-white hover:bg-gray-700 p-2 rounded transition w-full mr-10"
            >
              {!collapsed && (
                <>
                  <i
                    title="Collapse"
                    className="mdi mdi-arrow-collapse-left text-2xl"
                  />
                  <span>Collapse</span>
                </>
              )}

{collapsed && (
                <>
                  <i
                    title="expand"
                    className="mdi mdi-arrow-collapse-right text-2xl"
                  />
                </>
              )}
            </button>
          </div>
        </div>
      </aside>
    </>
  );
}

interface SidebarItemProps {
  icon: string;
  text: string;
  path: string;
  selected: boolean;
  collapsed?: boolean;
  onClick: (s: string) => void;
}

function SidebarItem({
  icon,
  text,
  path,
  selected,
  collapsed,
  onClick,
}: SidebarItemProps) {
  return (
    <div
      className={`rounded-lg hover:bg-gray-700 ${ collapsed ? 'w-fit' :''} ${
        selected ? "bg-gradient-to-r from-blue-500 to-purple-600" : "text-white"
      }`}
    >
      <button
        onClick={() => onClick(path)}
        className={`flex items-center space-x-2 w-full ml-2 py-1  hover:text-indigo-100 rounded transition justify-start ${"text-white"}`}
      >
        <i title={text} className={`mdi ${icon} text-3xl pr-2`} />
        {!collapsed && <span>{text}</span>}
      </button>
    </div>
  );
}
