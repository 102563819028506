import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from "amazon-cognito-identity-js";

import * as jwtDecode from "jwt-decode";

export default class AuthService {
  pool: CognitoUserPool;

  constructor(region: string, poolId: string, clientId: string) {
    this.pool = new CognitoUserPool({
      UserPoolId: poolId,
      ClientId: clientId,
    });
  }

  signOut = async () => {
    const user = this.pool.getCurrentUser();
    user?.signOut();
  };

  signIn = async (
    email: string,
    password: string,
    onSuccessCallback: (obj: any) => void,
    onNewPasswordRequired: (attr: any, requiredAttr: any) => void
  ) => {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email,
      Pool: this.pool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: onSuccessCallback,
      newPasswordRequired: onNewPasswordRequired,
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  newPassword = async (
    email: string,
    password: string,
    newPassword: string,
    onSuccessCallback: (obj: any) => void
  ) => {
    const authenticationData = {
      Username: email,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: email,
      Pool: this.pool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: onSuccessCallback,
      newPasswordRequired: ( (userAttributes, requiredAttributes) => {
        // the api doesn't accept this field back. See https://www.npmjs.com/package/amazon-cognito-identity-js
        delete userAttributes.email_verified;
        delete userAttributes.email;

        cognitoUser.completeNewPasswordChallenge(newPassword, userAttributes, {
          onSuccess: onSuccessCallback,
          onFailure: (err) => alert(err)
        })
      }),
      onFailure: function (err) {
        alert(err.message || JSON.stringify(err));
      },
    });
  };

  signUp = async (email: string, password: string) => {
    this.pool.signUp(email, password, [], [], function (err, result) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
    });

    this.pool.getCurrentUser();
  };

  confirmSignUp = async (email: string, code: string) => {
    const userData = {
      Username: email,
      Pool: this.pool,
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.confirmRegistration(code, true, function (err, result) {
      if (err) {
        alert(err.message || JSON.stringify(err));
        return;
      }
    });
  };

  forgotPassword = async (
    email: string,
    onSuccess: (data: any) => void,
    onFailure: (err: any) => void
  ) => {
    const user = new CognitoUser({
      Username: email,
      Pool: this.pool,
    });

    user.forgotPassword({
      onSuccess,
      onFailure,
    });
  };

  confirmPassword = async (
    email: string,
    code: string,
    password: string,
    onSuccess: (data: any) => void,
    onFailure: (err: any) => void
  ) => {
    const user = new CognitoUser({
      Username: email,
      Pool: this.pool,
    });

    user.confirmPassword(code, password, {
      onSuccess,
      onFailure,
    });
  };

  logout = (callback: () => void) => {
    try {
      const user = this.pool.getCurrentUser();

      if (user) {
        user.signOut();
      }
    } catch (e) {
      console.error(e);
    } finally {
      callback();
    }
  };

  getUser = async (onError?: () => {}): Promise<CognitoUser> => {
    const user = this.pool.getCurrentUser();

    if (!user) {
      if (onError) onError();
      throw new Error("No user is currently authenticated");
    }

    return user;
  };

  getUserAttributes = async (onError?: () => {}) => {
    const user = this.pool.getCurrentUser();

    if (!user) {
      if (onError) onError();
      throw new Error("No user is currently authenticated");
    }

    return new Promise((resolve, reject) => {
      user.getSession((err: any, session: any) => {
        if (err || !session.isValid()) {
          if (onError) onError();
          reject("User session is invalid or expired.");
          return;
        }

        user.getUserAttributes((attrErr, attributes) => {
          if (attrErr || !attributes) {
            reject(attrErr || "No attributes found.");
          } else {
            resolve(attributes);
          }
        });
      });
    });
  };

  checkUserAuthed = async (): Promise<CognitoUser> => {
    const user = this.pool.getCurrentUser()?.getSession(() => {
      throw Error("User Not Logged in.");
    });

    if (!user) {
      throw new Error("No user is currently authenticated");
    }

    return user;
  };

  getToken = async (): Promise<string> => {
    const user = this.pool.getCurrentUser();

    if (!user) {
      throw new Error("No user is currently authenticated");
    }

    return new Promise((resolve, reject) => {
      user.getSession((err: any, session: any) => {
        if (err) {
          reject(err);
          return;
        }

        const idToken = session.getIdToken().getJwtToken();
        const refreshToken = session.getRefreshToken();
        const decodedToken = jwtDecode.jwtDecode(idToken) as { exp: number };

        // Check if token is expired or about to expire in the next 5 minutes
        const isExpired = decodedToken.exp * 1000 < Date.now() + 5 * 60 * 1000;

        if (isExpired && refreshToken) {
          // Refresh the token if expired or about to expire
          user.refreshSession(refreshToken, (refreshErr, newSession) => {
            if (refreshErr) {
              reject(refreshErr);
              return;
            }

            const newIdToken = newSession.getIdToken().getJwtToken();
            resolve(newIdToken); // Return the new ID token
          });
        } else {
          // Token is still valid
          resolve(idToken);
        }
      });
    });
  };
}
