import { ComplianceResult, InfraScanPayload, Scan } from "common";
import React from "react";
import FailureGuage from "./FailureGauge";

interface Props {
  scan: Scan;
    maxResults: number;
}

export default function TopNFailingCompliances({ scan, maxResults }: Props) {
  const complianceResults = (
    scan.Targets.find((t) => t.Type === "InfrastructureScan") as InfraScanPayload
  ).ComplianceResults as ComplianceResult[];

  return (
    <>
      {complianceResults && complianceResults
        .sort((a, b) => a.Failures - b.Failures)
        .slice(0, maxResults)
        .map((result) => {
          return (
            <div className=" h-80 bottom-4 max-w-sm p-6 bg-white border border-gray-200 dark:border-gray-800 rounded-lg bg-gradient-to-bl from-indigo-900 from-1% via-gray-900 via-30% to-gray-900 to-90% shadow-sm shadow-gray-800">
            <h5 className="mb-2 text-center text-md font-bold tracking-tight text-gray-900 dark:text-gray-400" style={{ height: '3rem', overflow: 'hidden' }}>
              {result.ComplianceFramework}
            </h5>
            
            <FailureGuage failures={result.Failures} passes={result.Passes} other={result.Other} total={result.Failures + result.Passes + result.Other} />
          </div>
          
          );
        })}
    </>
  );
}
