import { ApexOptions } from "apexcharts";
import { Severity } from "common";
import React from "react";
import ReactApexChart from "react-apexcharts";
import { colorCodes } from "../../ColorCodes";

interface Props {
  failures: number;
  passes: number;
  other: number;
  total: number;
}

export default function FailureGauge({ passes, other, failures, total }: Props) {
  const state: { series: number[]; options: ApexOptions } = {
    series: [failures, passes, other],
    options: {
      chart: {
        height: 350,
        type: "pie",
        toolbar: {
          show: false,
        },
        foreColor: 'red'
      },
      plotOptions: {
      },
      
      colors:[colorCodes.CRITICAL.asHex, '#0b0', '#aaa'],
      labels: ["Failures", "Passes", "Other"],
    },
  };

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.series}
        type="pie"
        height={350}
      />
    </>
  );
}
