import React, { useState } from "react";
import { ReportItem, Scan } from "common";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import Badge, { fromReportItem } from "../../../../Badge";
import { colorCodes } from "../../../../../ColorCodes";

interface Props {
  scan: Scan;
  type: string;
  reportItems: ReportItem[];
  selectedSeverity?: string;
  onNextClick: (last: ReportItem) => void;
  onPreviousClick: (previousReportItem: ReportItem) => void;
  onFocusReportItem: (reportItem: ReportItem) => void;
  onSeverityClick: (severity: any) => void;
  onTargetChange: (severity: number) => void;
  previousButtonDisabled: boolean;
  nextButtonDisabled: boolean;
}

export default function ResultTable({
  scan,
  type,
  reportItems,
  selectedSeverity,
  onSeverityClick,
  onNextClick,
  onPreviousClick,
  previousButtonDisabled,
  nextButtonDisabled,
  onTargetChange,
  onFocusReportItem,
}: Props) {
  const [selectedTargetIndex, setSelectedTargetIndex] = useState(0);

  const headers = [
    {
      id: "target",
      title: "Target Id",
      transformationFunc: (item: ReportItem) =>
        item.TargetId
    },
    {
      id: "title",
      title: "Title",
      transformationFunc: (item: ReportItem) =>
        item.Finding.vulnerabilities?.[0]?.title ||
        item.Finding.finding_info["desc"],
    },
    {
      id: "date",
      title: "Date",
      transformationFunc: (item: ReportItem) => new Date(item.Finding.time).toISOString(),
    },
    {
      id: "severity",
      title: "Severity",
      transformationFunc: (item: ReportItem) => fromReportItem(item),
    },
  ];

  return (
    <>
      <div className="py-4 flex items-center">
        <span className="space-x-2">
          <span className="bg-gray-900 text-gray-200 font-semibold py-2 px-4 border border-gray-800 rounded mr-2">
            Filter by:
          </span>
          <select
            className="bg-gray-800 text-white p-2 mx-2 rounded"
            value={selectedTargetIndex}
            onChange={(e) => { const val = Number(e.target.value); onTargetChange(val); setSelectedTargetIndex(val) }}
          >
            {scan.Targets.map((target, index) => (
              target.Type === type ? (
                <option key={index} value={index}>
                  Target: {index}
                </option>
              ) : null
            ))}
          </select>
          {Object.keys(colorCodes)
            .filter((e) => isNaN(Number(e)))
            .map((severity) => (
              <Badge
                key={severity}
                text={`${severity}`}
                backgroundColor={"bg-gray-800"}
                textColor={
                  severity === selectedSeverity
                    ? colorCodes[severity].textColor
                    : "text-gray-500"
                }
                borderColor={
                  severity === selectedSeverity
                    ? colorCodes[severity].borderColor
                    : "border-gray-500"
                }
                onClick={() => onSeverityClick(severity)}
                className="rounded"
              />
            ))}
        </span>
      </div >

      <table className="table w-full">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
          <tr>
            <th></th>
            {headers.map((header) => (
              <th key={header.id} className="max-w-xs">
                {header.title}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="font-medium text-sm text-gray-900 dark:text-white">
          {reportItems.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              className={rowIndex % 2 === 1 ? "bg-gray-800" : ""}
            >
              <td>
                <button
                  className="text-indigo-600"
                  onClick={() => onFocusReportItem(row)}
                >
                  <Icon path={mdiChevronRight} size={1} />
                </button>
              </td>
              {headers.map((column, columnIndex) => (
                <td
                  key={`${rowIndex}-${columnIndex}`}
                  className="max-w-xs whitespace-normal overflow-hidden text-ellipsis"
                >
                  {column.transformationFunc(row)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div className="flex py-4 right-10 absolute">
        <button
          disabled={previousButtonDisabled}
          onClick={() => onPreviousClick(reportItems[0])}
          className={
            !previousButtonDisabled
              ? "flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-100 bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-indigo-300 dark:focus:ring-indigo-800 rounded-lg"
              : "flex items-center justify-center px-3 h-8 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          }
        >
          Previous
        </button>

        <button
          disabled={nextButtonDisabled}
          onClick={() => onNextClick(reportItems[reportItems.length - 1])}
          className={
            !nextButtonDisabled
              ? "flex items-center justify-center px-3 h-8 ms-3 text-sm font-medium text-gray-100 bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-indigo-300 dark:focus:ring-indigo-800 rounded-lg"
              : "flex items-center justify-center px-3 h-8 ms-3 text-sm font-medium text-gray-500 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
          }
        >
          Next
        </button>
      </div>
    </>
  );
}
