import { ApexOptions } from "apexcharts";
import { Scan } from "common"; // Make sure Scan type includes Targets array
import * as React from "react";
import Chart from "react-apexcharts";

interface Props {
  scans: Scan[];
}

export default function ScanLineGraph({ scans }: Props) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const goBackDays = 7;

  // Initialize counts for the last 7 days
  const infraScanCounts = new Array(goBackDays).fill(0);
  const codeScanCounts = new Array(goBackDays).fill(0);

  // Get today's date
  const today = new Date();
  
  // Loop through the scans and categorize them by day
  scans.forEach((scan) => {
    const scanDate = new Date(scan.CreatedAt);
    const dayDifference = Math.floor((today.getTime() - scanDate.getTime()) / (1000 * 3600 * 24));

    // Check if the scan is within the last 7 days
    if (dayDifference >= 0 && dayDifference < goBackDays) {
      const dayIndex = goBackDays - 1 - dayDifference; // Calculate the index for the counts array

      // Iterate over each Target and count types
      scan.Targets.forEach((target) => {
        const scanType = target.Type; // Access the Type from each Target

        if (scanType === "InfrastructureScan") {
          infraScanCounts[dayIndex] += 1; // Increment InfraScan count for the day
        } else if (scanType === "CodeScan") {
          codeScanCounts[dayIndex] += 1; // Increment CodeScan count for the day
        }
      });
    }
  });

  // Prepare series data
  const series = [
    {
      name: "Infrastructure Scans",
      data: infraScanCounts,
    },
    {
      name: "Code Scans",
      data: codeScanCounts,
    },
  ];

  // Create an array for the last 7 days to use as categories
  const daysSorted = Array.from({ length: goBackDays }, (_, i) => {
    const pastDate = new Date();
    pastDate.setDate(today.getDate() - (i + 1)); // Get past dates
    return days[pastDate.getDay()]; // Get the day name
  }).reverse(); // Reverse to show the most recent day first

  const state: { options: ApexOptions; series: ApexAxisChartSeries } = {
    options: {
      legend: {
        labels: {
          colors: '#fff'
        }
      },
      chart: {
        toolbar: {
          show: false,
        },
        id: "apexchart-example",
      },
      yaxis: {
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },
      xaxis: {
        categories: daysSorted,
        labels: {
          style: {
            colors: "#fff",
          },
        },
      },
    },
    series: series,
  };

  return (
    <div className="max-w-sm p-6 bg-white border border-gray-200 rounded-lg dark:border-gray-800 rounded-lg bg-gradient-to-bl from-emerald-900 from-1% via-gray-900 via-25% to-gray-900 to-90% shadow-sm shadow-gray-800">
      <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-gray-400">
        Requested Scans
      </h5>
      <p className="mb-14 font-normal text-gray-700 dark:text-gray-400">
        Scans per Day Scan Type
      </p>

      <Chart
        options={state.options}
        series={state.series}
        type="bar"
        width={300}
        height={320}
      />
    </div>
  );
}
