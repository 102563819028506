/* eslint-disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface ReportItem {
  /**
   * Id of the Report Item
   */
  Id?: string;
  /**
   * Unique identifier for the customer
   */
  CustomerId: string;
  /**
   * Concatenation of CustomerReportId and ScanType
   */
  CustomerReportIdAndScanType: string;
  /**
   * Concatenation of CustomerId and ReportId
   */
  CustomerAndReportId: string;
  /**
   * Unique identifier for the report
   */
  ReportId: number;
  /**
   * Unique identifier for the target
   */
  TargetId: number;
  Finding: VulnerabilityFinding;
  Severity?: Severity;
}
/**
 * Schema for vulnerability findings that report weaknesses in information systems
 */
export interface VulnerabilityFinding {
  /**
   * The finding activity name, as defined by the activity_id
   */
  activity_name?: string;
  activity_id: ActivityId;
  resources?: Resource[];
  /**
   * The event category name: Findings
   */
  category_name?: string;
  /**
   * The category unique identifier: Findings events
   */
  category_uid: 2;
  /**
   * The event class name: Vulnerability Finding
   */
  class_name?: string;
  /**
   * The unique identifier of the Vulnerability Finding class
   */
  class_uid: 2002;
  /**
   * A user provided comment about the finding
   */
  comment?: string;
  /**
   * The normalized confidence of the finding
   */
  confidence?: string;
  confidence_id?: ConfidenceId;
  /**
   * The confidence score as reported by the event source
   */
  confidence_score?: number;
  /**
   * The number of times events in the same logical group occurred
   */
  count?: number;
  /**
   * The event duration in milliseconds
   */
  duration?: number;
  /**
   * The time of the most recent event included in the finding
   */
  end_time?: string;
  enrichments?: {
    name: string;
    value: string;
    type: string;
    data: {
      [k: string]: unknown;
    };
    [k: string]: unknown;
  }[];
  /**
   * The normalized event occurrence time
   */
  time: string;
  /**
   * Describes the supporting information about a generated finding
   */
  finding_info: {
    [k: string]: unknown;
  };
  /**
   * The description of the event/finding
   */
  message?: string;
  /**
   * The metadata associated with the event
   */
  metadata: {
    [k: string]: unknown;
  };
  observables?: {
    [k: string]: unknown;
  }[];
  /**
   * The raw event/finding data as received from the source
   */
  raw_data?: string;
  /**
   * The event/finding severity
   */
  severity?: string;
  severity_id: SeverityId;
  /**
   * The time of the least recent event included in the finding
   */
  start_time?: string;
  /**
   * The normalized status of the Finding
   */
  status?: string;
  /**
   * The event status code, as reported by the event source
   */
  status_code?: string;
  /**
   * Additional information about the event/finding outcome
   */
  status_detail?: string;
  status_id?: StatusId;
  /**
   * The number of minutes that the reported event time is ahead or behind UTC
   */
  timezone_offset?: number;
  type_uid: TypeUid;
  /**
   * The event/finding type name
   */
  type_name?: string;
  /**
   * Attributes not mapped to the event schema
   */
  unmapped?: {
    [k: string]: unknown;
  };
  /**
   * Details about vulnerabilities reported in a security finding
   */
  vulnerabilities: Vulnerability[];
}
/**
 * Resource Details
 */
export interface Resource {
  /**
   * A list of agent objects associated with a device, endpoint, or resource.
   */
  agent_list?: {
    [k: string]: unknown;
  }[];
  /**
   * The criticality of the resource as defined by the event source.
   */
  criticality?: string;
  /**
   * Additional data describing the resource.
   */
  data?: {
    [k: string]: unknown;
  };
  /**
   * The name of the related resource group.
   */
  group?: string;
  /**
   * The list of labels/tags associated to a resource.
   */
  labels?: string[];
  /**
   * The name of the resource.
   */
  name?: string;
  /**
   * The namespace is useful when similar entities exist that you need to keep separate.
   */
  namespace?: string;
  /**
   * The identity of the service or user account that owns the resource.
   */
  owner?: {
    [k: string]: unknown;
  };
  /**
   * The resource type as defined by the event source.
   */
  type?: string;
  /**
   * The unique identifier of the resource.
   */
  uid?: string;
  /**
   * The version of the resource. For example 1.2.3.
   */
  version?: string;
  [k: string]: unknown;
}
export interface Vulnerability {
  /**
   * List of Affected Code objects that describe details about code blocks identified as vulnerable.
   */
  affected_code?: string[];
  /**
   * List of software packages identified as affected by a vulnerability/vulnerabilities.
   */
  affected_packages?: string[];
  /**
   * The Common Vulnerabilities and Exposures (CVE).
   */
  cve?: string;
  /**
   * The CWE object represents a weakness in a software system that can be exploited by a threat actor to perform an attack. The CWE object is based on the Common Weakness Enumeration (CWE) catalog.
   */
  cwe?: string;
  /**
   * The description of the vulnerability.
   */
  desc?: string;
  /**
   * Indicates if an exploit or a PoC (proof-of-concept) is available for the reported vulnerability.
   */
  is_exploit_available?: boolean;
  /**
   * The time when the vulnerability was first observed.
   */
  first_seen_time?: string;
  /**
   * Indicates if a fix is available for the reported vulnerability.
   */
  is_fix_available?: boolean;
  /**
   * A list of KB articles or patches related to an endpoint. A KB Article contains metadata that describes the patch or an update.
   */
  kb_article_list?: {
    [k: string]: unknown;
  }[];
  /**
   * DEPRECATED since v1.1.0. Use the kb_article_list attribute instead. The KB article/s related to the entity. A KB Article contains metadata that describes the patch or an update.
   */
  kb_articles?: string[];
  /**
   * The time when the vulnerability was most recently observed.
   */
  last_seen_time?: string;
  /**
   * A list of reference URLs with additional information about the vulnerability.
   */
  references?: string[];
  /**
   * List of vulnerabilities that are related to this vulnerability.
   */
  related_vulnerabilities?: string[];
  remediation?: Remediation;
  /**
   * The vendor assigned severity of the vulnerability.
   */
  severity?: string;
  /**
   * A title or a brief phrase summarizing the discovered vulnerability.
   */
  title?: string;
  /**
   * The name of the vendor that identified the vulnerability.
   */
  vendor_name?: string;
  [k: string]: unknown;
}
export interface Remediation {
  /**
   * The description of the remediation strategy.
   */
  desc: string;
  /**
   * A list of KB articles or patches related to an endpoint. A KB Article contains metadata that describes the patch or an update.
   */
  kb_article_list?: string[];
  /**
   * DEPRECATED since v1.1.0. Use the kb_article_list attribute instead. A list of KB article/s related to the entity. A KB Article contains metadata that describes the patch or an update.
   */
  kb_articles?: string[];
  /**
   * A list of supporting URL/s, references that help describe the remediation strategy.
   */
  references?: string[];
  [k: string]: unknown;
}
/**
 * Indicates the severity level of an issue or alert.
 */
export interface Severity {
  [k: string]: unknown;
}

/**
 * The normalized identifier of the finding activity
 */
export enum ActivityId {
  UNKNOWN = 0,
  CREATE = 1,
  UPDATE = 2,
  CLOSE = 3,
  OTHER = 99
}
/**
 * The normalized confidence identifier
 */
export enum ConfidenceId {
  UNKNOWN = 0,
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3,
  OTHER = 99
}
/**
 * The normalized identifier of the event/finding severity
 */
export enum SeverityId {
  UNKNOWN = 0,
  INFORMATIONAL = 1,
  LOW = 2,
  MEDIUM = 3,
  HIGH = 4,
  CRITICAL = 5,
  FATAL = 6,
  OTHER = 99
}
/**
 * The normalized status identifier
 */
export enum StatusId {
  UNKNOWN = 0,
  NEW = 1,
  IN_PROGRESS = 2,
  SUPRESSED = 3,
  RESOLVED = 4,
  OTHER = 99
}
/**
 * The event/finding type ID (class_uid * 100 + activity_id)
 */
export enum TypeUid {
  UNKNOWN = 200200,
  CREATE = 200201,
  UPDATE = 200202,
  CLOSE = 200203,
  OTHER = 200299
}
