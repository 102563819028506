import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiCheck, mdiCubeScan, mdiDownload, mdiHome } from "@mdi/js";
import { CodeScanPayload, InfraScanPayload, ReportItem, Scan } from "common";
import ScanService from "../../../service/ScanService";
import ReportService from "../../../service/ReportService";
import BreadCrumbs from "../../Breadcrumbs";
import Badge from "../../Badge";
import { TabPicker } from "../../Tab";
import ReportItemDetailModal from "./tabs/ReportItemDetailModal";
import InfrastructureTab from "./tabs/InfrastructureTab";
import CodeScanTab from "./tabs/CodeScanTab";
import ComplianceTab from "./tabs/ComplianceTab";
import ExternalScanTab from "./tabs/ExternalScanTab";
import Loading from "../../Loading";
import React from "react";

interface Props {
  scanService: ScanService;
  reportService: ReportService;
}

type TabConfig = {
  name: string;
  scanType: string;
  component: React.ComponentType<any>;
  shouldRender: (scan: Scan) => boolean;
};

const TABS: TabConfig[] = [
  {
    name: "Infrastructure",
    scanType: "InfrastructureScan",
    component: InfrastructureTab,
    shouldRender: (scan) => scan.Targets.some(t => t.Type === "InfrastructureScan")
  },
  {
    name: "Compliance",
    scanType: "InfrastructureScan",
    component: ComplianceTab,
    shouldRender: (scan) => scan.Targets.some(t => t.Type === "InfrastructureScan")
  },
  {
    name: "External Scan",
    scanType: "NetworkScan",
    component: ExternalScanTab,
    shouldRender: (scan) => scan.Targets.some(t => t.Type === "InfrastructureScan")
  },
  {
    name: "Code Scan",
    scanType: "CodeScan",
    component: CodeScanTab,
    shouldRender: (scan) => scan.Targets.some(t => t.Type === "CodeScan")
  }
];

export default function DetailScan({ scanService, reportService }: Props) {
  const { id } = useParams();
  const scanId = Number(id);

  const [activeTab, setActiveTab] = useState<string>("");
  const [scan, setScan] = useState<Scan>();
  const [report, setReport] = useState<ReportItem[]>();
  const [targetId, setTargetId] = useState<number>(0);
  const [selectedReportItem, setSelectedReportItem] = useState<ReportItem>();
  const [severityFilter, setSeverityFilter] = useState<string>();
  const [scanFilter, setScanFilter] = useState<string>();
  const [pagination, setPagination] = useState({
    nextDisabled: false,
    previousDisabled: true
  });

  // Filter tabs based on scan type
  const availableTabs = scan
    ? TABS.filter(tab => tab.shouldRender(scan)).map(tab => tab.name)
    : [];

  useEffect(() => {
    const initializeScan = async () => {
      const scanData = await scanService.get(scanId);
      setScan(scanData);

      // Set initial tab and scan filter based on first available tab
      const firstTab = TABS.find(tab => tab.shouldRender(scanData));
      if (firstTab) {
        setActiveTab(firstTab.name);
        setScanFilter(firstTab.scanType);
        await loadReports(scanId, targetId, undefined, undefined, undefined, firstTab.scanType);
      }
    };

    initializeScan();
  }, [scanId]);

  const loadReports = async (
    scanId: number,
    targetId: number,
    lastToken?: string,
    paginateBackwards?: boolean,
    severity?: string,
    scanType?: string
  ) => {
    const reportResponse = await reportService.getItems(
      scanId,
      lastToken,
      paginateBackwards,
      { severity, scanType: scanType ?? scanFilter }
    );

    setReport(reportResponse.items);
    setPagination({
      nextDisabled: !reportResponse.hasMore,
      previousDisabled: !lastToken || paginateBackwards === false
    });
  };

  const handleTabChange = async (tabName: string) => {
    const tab = TABS.find(t => t.name === tabName);
    if (!tab) return;

    setActiveTab(tabName);
    setScanFilter(tab.scanType);
    setReport([]);
    setPagination({ nextDisabled: false, previousDisabled: true });
    await loadReports(scanId, targetId, undefined, undefined, severityFilter, tab.scanType);
  };

  const handleSeverityFilter = async (severity: string) => {
    const newSeverity = severity === severityFilter ? undefined : severity;
    setSeverityFilter(newSeverity);
    await loadReports(scanId, targetId, undefined, undefined, newSeverity);
    setPagination({ nextDisabled: false, previousDisabled: true });
  };

  const handlePagination = async (lastItem: ReportItem, direction: 'next' | 'previous') => {
    const isPrevious = direction === 'previous';
    await loadReports(scanId, targetId, lastItem.Id, isPrevious, severityFilter, scanFilter);
  };

  const onTargetChange = (targetId: number) => {
    setTargetId(targetId)
  }

  if (!scan || !report) return <Loading />;

  const downloadReport = () => reportService.get(scanId);

  const downloadComplianceReport = (frameworkId: number, targetId: number) => {
    reportService.downloadComplianceReport(scanId, targetId, frameworkId);
  };

  return (
    <>
      <div className="grid grid-cols-2 gap-2 max-h-64">
        <div className="pt-10">
          <BreadCrumbs
            items={[
              { iconPath: mdiHome, text: "home", link: "/home" },
              { text: "Scan", link: "/scan" },
              { text: scan.ReportId.toString(), link: `/scan/${scan.ReportId}` },
            ]}
          />

          <h2 className="text-gray-300 text-xl mt-8 mb-4">
            Scanned {scan.Targets.length} targets
          </h2>

          <span className="space-x-4">
            {scan.Targets.map((target: InfraScanPayload | CodeScanPayload) => (
              <Badge
                key={target.Type}
                borderColor="border-indigo-400"
                backgroundColor="bg-gray-900"
                textColor="text-indigo-400"
                className="rounded"
                text={target.Type}
                iconPath={target.Type === "InfrastructureScan" ? mdiCubeScan : mdiCheck}
              />
            ))}
          </span>
        </div>

        <div className="pt-10">
          <button
            className="absolute right-10 inline-flex h-10 text-white bg-gradient-to-br from-purple-600 to-blue-500 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center my-2 ml-auto"
            onClick={downloadReport}
          >
            Download Your Report
            <Icon path={mdiDownload} className="pb-3 text-white" size={1.5} />
          </button>
        </div>
      </div>

      <div className="py-5" />

      <TabPicker
        options={availableTabs}
        firstSelectedByDefault={true}
        onChange={handleTabChange}
      />

      {selectedReportItem && (
        <ReportItemDetailModal
          reportItem={selectedReportItem}
          buttonText="Go Back"
          onClick={() => setSelectedReportItem(undefined)}
          onEnrichRemediationWithAi={() => { }}
        />
      )}

      {TABS.map(tab => {
        if (activeTab !== tab.name) return null;

        const TabComponent = tab.component;
        return (
          <TabComponent
            key={tab.name}
            onTargetChange={onTargetChange}
            scan={scan}
            reportItems={report}
            selectedSeverity={severityFilter}
            onNextClick={(item: ReportItem) => handlePagination(item, 'next')}
            onPreviousClick={(item: ReportItem) => handlePagination(item, 'previous')}
            onSeverityClick={handleSeverityFilter}
            previousButtonDisabled={pagination.previousDisabled}
            nextButtonDisabled={pagination.nextDisabled}
            onFocusReportItem={setSelectedReportItem}
            onFrameworkClick={downloadComplianceReport}
          />
        );
      })}
    </>
  );
}